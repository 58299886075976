









































import { Component, Vue } from 'vue-property-decorator'
import { getContactActivities } from '@/api/consoleApi/recipients'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import { groupBy, orderBy } from 'lodash'
import moment from 'moment'

interface IActivity {
    time: number
    activity: string
    extra: string
}

@Component({
    name: 'ContactActivities',
    components: {
        VsLoader,
        VsSeparator,
    },
})
export default class extends Vue {
    private loading = false
    private activities: any[] = []
    private activityType = 'all'
    private search = ''

    get listId () {
        return this.$route?.params?.listId
    }

    get contactId () {
        return this.$route?.params?.contactId
    }

    get monthActivities () {
        if (!this.activities || this.activities.length === 0) return []
        return groupBy(
            orderBy(this.activities, 'time', 'desc'),
            (result) => moment.unix(result['time']).format('YYYY-MM'),
        )
    }

    beforeMount () {
        this.getContactActivities()
    }

    private async getContactActivities () {
        this.loading = true
        try {
            const resp = await getContactActivities(this.listId, this.contactId)
            this.activities = resp.data
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }
}
